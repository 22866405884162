import React, { createRef, useEffect, useState } from 'react';
import { Editor as DraftEditor } from "react-draft-wysiwyg";
import { Controller, } from 'react-hook-form'
import draftToHtml from "draftjs-to-html";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { VariableAPI } from "new/functions/api_tools";
import { toast } from "react-toastify";
import styled from "styled-components";
import _uniqueId from 'lodash/uniqueId';
import _snakeCase from 'lodash/snakeCase';
import {
  Chip, Autocomplete, TextField, Tooltip, Grid, CircularProgress, Dialog, Toolbar, Typography,
  Box, Button as MuiButton, Checkbox, List, ListItem, Radio, Stack
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { appearanceNone, respondTo } from "./mixins";
import * as luxon from "luxon";
import ReactTooltip from "react-tooltip"
import { AttachmentManager } from "new/components/AttachmentManager";
import { useConfirm, ConfirmProvider } from 'material-ui-confirm';
import jsonpath from "jsonpath";
import { titleize } from "inflected";
import { KnownTypes } from "../functions/Uploader";
import htmlToDraft from 'html-to-draftjs';
import ArticleIcon from "@mui/icons-material/Article";
import { AutocompleteElement } from 'react-hook-form-mui'
import { Snackbar } from "@variable/ui-theme";

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`
export const Title = styled.div`
  display: flex;
  flex: 0 1 100%;
  font-size: 1.8rem;
  color: var(--color-darkest_gray);
  margin-bottom: 40px;
  justify-content: center;
  font-weight: 600;
`
export const SubTitle = styled.div`
  display: flex;
  flex: 0 1 100%;
  font-size: 1.3rem;
  color: var(--color-darkest_gray);
  margin-bottom: 40px;
  justify-content: center;
  font-weight: 600;
`
export const SectionForm = styled(Form)`
  max-width: 700px !important;
  padding: 30px;
  margin-top: 10px;
  margin-left: 30px;

  div${Title} {
    text-align: left;
    justify-content: left;
  }
`

export const Input = styled.input`
  &&& {
    display: flex;
    flex: 0 1 auto;
    width: ${props => props.width ? props.width : "100%"};

    ${respondTo.mobile`  
        width: ${props => props.mobileWidth ? props.mobileWidth : "100%"};
    `}
    &.error {
      color: var(--color-error);
      background-color: #FFEEEE;
    }
  }
`

export const ErrorMessage = styled.div`
  display: flex;
  flex: 0 1 auto;
  width: ${props => props.width ? props.width : "100%"};
  color: red;
  margin-left: 2px;
  margin-top: 5px;
  font-size: 14px;
  text-transform: capitalize;
`

export const Label = styled.label`
  display: flex;
  flex: 0 1 auto;
  width: ${props => props.width ? props.width : "100%"};
  ${respondTo.mobile`  
        width: ${props => props.mobileWidth ? props.mobileWidth : "100%"};
    `}
  font-size: 1.4rem;
  color: var(--color-darkest_gray);
  margin-bottom: ${props => props.marginBottom || "8px"};

  &.error {
    color: var(--color-error);
  }
`
export const ActionContainer = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-top: 40px;
`

export const Button = styled(Input).attrs({ type: 'submit' })`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
  box-sizing: border-box;
  border: none;
  outline: none;

  &&& {
    height: 40px;
    width: 160px;
  }
`
export const SubmitButtonBase = styled(Button)`
  color: white;
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'var(--color-bright_green)'};

  &.connection {
    background-color: var(--color-link)
  }

  &.disabled {
    opacity: 0.2;
  }

  :disabled {
    opacity: 0.2;
  }
`
export const CancelButtonBase = styled(Button)`
  color: ${props => props.value === 'Delete' ? 'white' : 'var(--color-darkest_gray);'};
  background-color: ${props => props.value === 'Delete' ? 'var(--color-error)' : 'var(--color-gray_cancel)'};

  &.disabled {
    opacity: 0.2;
  }
`
export const WizardButtonBase = styled(Button)`
  border: 1px solid var(--color-course-purple);
  color: white;
  background-color: ${props => props.backgroundColor || 'var(--color-bright_green)'};
  transition: background-color 0.2s, color 0.2s;

  &.course {
    background-color: var(--color-course-purple)
  }

  &.organization,
  &.connection {
    background-color: var(--color-link);
  }


  &:hover {
    color: white;
  }

  &.disabled,
  &:disabled {
    opacity: 0.2;
  }

  &.loading {
    background-image: url("/assets/loading.gif")
    background-position: left;
    background-size: "16px";
  }
`

export const DurationWrapper = styled.div`
  display: block;
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
`
export const SubmitButton = ({ onSubmit, value = "Submit", submitting, submittingMessage = "Submitting...", ...props }) =>
  <SubmitButtonBase className={submitting ? "submitting" : ""} value={submitting ? submittingMessage : value}
    onClick={onSubmit} {...props} />
export const SaveButton = ({ onSubmit, value = "Save", submitting, submittingMessage = "Saving...", ...props }) =>
  <SubmitButtonBase value={submitting ? submittingMessage : value} onClick={onSubmit} {...props} />
export const CancelButton = ({ onCancel, value = "Cancel", ...props }) => <CancelButtonBase value={value}
  onClick={onCancel} {...props} />
export const ResetButton = ({ onReset, value = "Reset", ...props }) => <CancelButtonBase value={value}
  onClick={onReset} {...props} />
export const NextButton = ({ onNext, value = "Next", ...props }) => <WizardButtonBase value={value}
  onClick={onNext} {...props} />
export const PreviousButton = ({ onPrevious, value = "Previous", ...props }) => <WizardButtonBase value={value}
  onClick={onPrevious} {...props} />


export const TextInput = styled(Input).attrs({ type: 'text' })``
export const EmailInput = styled(Input).attrs({ type: 'email' })``

export const HiddenInput = styled(Input).attrs({ type: 'hidden' })``
export const NumberInput = styled(Input).attrs({ type: 'number' })``
export const ColorInput = styled(Input).attrs({ type: 'color' })``


export const DateInput = styled(Input).attrs({ type: 'date' })`
  border: solid 1px var(--color-light-gray);
  height: 40px;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  width: 400px;
  max-width: 400px;

  &:focus {
    border: var(--color-link) 1px solid;
    outline: none;
  }

  ::-webkit-calendar-picker-indicator {
    background-image: url("/assets/svg/calendar_blue.svg");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
  }
`
export const TimeInput = styled(Input).attrs({ type: 'time' })`
  border: solid 1px var(--color-link);
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  box-sizing: border-box;
`
export const TelInput = styled(Input).attrs({ type: 'tel' })``
export const CheckboxLabel = styled(Label)`
  &&& {
    align-self: unset;
  }
`
export const CheckboxInput = styled(Input).attrs({ type: 'checkbox' })`
  &&& {
    width: 20px;
    height: 20px;
    background-color: white;
  }
`
export const RadioLabel = styled(Label)`
  &&& {
    align-self: unset;
  }
`
export const RadioInput = styled(Input).attrs({ type: 'radio' })`
  &&& {
    // width: 20px;
    // height: 20px;
  }

  //RGC: Remove the cicle :)
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
`
export const Select = styled.select`
  max-width: 400px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  color: #666666;
  border: 1px solid var(--color-light-gray);
  outline: none;
  ${appearanceNone()}
  background-repeat: no-repeat;
  background-position: right 14px center;
  background-image: url("/assets/icons/icon_arrow_expand_blue_12.png");

  &:focus {
    border: 1px solid var(--color-link);
  }
`

export const StyledSelect = styled(Select)``

export const SelectOption = styled.option`
  font-size: 16px;
`

export const TrixWrap = styled.div`
  width: 100%;
  font-size: 1.6rem;
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;

  trix-toolbar {
    display: inline-block;
    font-size: 1.2rem;
    margin-bottom: 4px;
    max-width: 100%;

    .trix-button-group {
      margin-bottom: 0;
    }
  }

  trix-editor {
    padding: 0.4em 0.6em;
    min-height: 5em;
    outline: none;
    margin-bottom: 10px;
    background-color: white;
    line-height: 1.5;
    box-sizing: border-box;
    text-align: left;
    border: 1px solid var(--color-light-gray);
    border-radius: 5px;
    resize: vertical;
    overflow: auto;
    width: ${props => props.width ? props.width : "100%"}
  }

  trix-toolbar .trix-button-row {
    flex-wrap: initial;
    width: ${props => props.width ? props.width : "100%"}
  }
`
export const TrixEditor = styled('trix-editor').withConfig({ shouldForwardProp: (prop, defaultValidatorFn) => ['input'].includes(prop) })`
  &&& {
    padding: 0.4em 0.6em;
    min-height: 5em;
    outline: none;
    margin-bottom: 10px;
    background-color: green;
    line-height: 1.5;
    box-sizing: border-box;
    text-align: left;
    border: 1px solid var(--color-light-gray);
    border-radius: 5px;
    resize: vertical;
    overflow: auto;
  }
`

export const HelpTipImage = styled.img`
  background-image: url("/assets/svg/items/help.svg");
  width: 15px;
  height: 15px;

  &.help-requirement {
    background-image: url("/assets/svg/items/help-blue.svg");
  }
`

export const TrixEditorInput = React.forwardRef((props, ref) => {
  const trixRef = createRef();
  const trixHiddenRef = createRef();
  const [defaultValue, setDefaultValue] = useState(props.defaultValue || "N/A");

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value)
    }
    setDefaultValue(e.target.value)
  }

  const wrapTrixRef = e => {
    try {
      ref(e)
      trixHiddenRef.current = e
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    trixRef.current.editor.insertHTML(defaultValue);
    trixRef.current.addEventListener('trix-change', handleChange);
  }, []);

  return (<>
    <input type="hidden" ref={ref} name={props.name} />
    <TrixWrap {...props}>
      <input ref={wrapTrixRef} id={props.id} name={`${props.name}`} type="hidden"
        defaultValue={defaultValue} />
      <TrixEditor ref={trixRef} input={props.id} />
    </TrixWrap>
  </>)
})

// const FroalaEditorWrapper = styled(FroalaEditor)`
//     .fr-box .fr-basic {
//         width: 100%;
//     }
//
//     .fr-box .fr-toolbar .fr-btn-grp button svg {
//         height: 20px;
//     }
//
//     .fr-box .fr-toolbar .fr-btn-grp button fr-svg {
//         height: 20px;
//     }
// `
// export const TextEditorInput = React.forwardRef((props, ref) => {
//     const [model, setModel] = useState(props.defaultValue ? props.defaultValue : '');
//
//     return (
//         <FroalaEditorWrapper
//             tag='textarea'
//             model={model}
//             onModelChange={(model) => {
//                 setModel(model);
//                 props.control && props.control.register(props.name);
//                 props.setValue && props.setValue(props.name, model);
//             }}
//             config={{
//                 attribution: false
//             }}
//         />
//     )
// })


export const DurationInput = React.forwardRef((props, ref) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [duration, setDuration] = useState(props.defaultValue || 0)

  useEffect(() => {
    let d = luxon.Duration.fromMillis(duration * 1000).shiftTo('days', 'hours', 'minutes', 'seconds').toObject()
    setDays(d.days)
    setHours(d.hours)
    setMinutes(d.minutes)
    setSeconds(d.seconds)
  }, [])


  useEffect(() => {
    let daysToSeconds = (parseInt(days) * 24 * 60 * 60) || 0;
    let hoursToSeconds = (parseInt(hours) * 60 * 60) || 0;
    let minutesToSeconds = (parseInt(minutes) * 60) || 0;
    if (props.onChanged) {
      props.onChanged(daysToSeconds + hoursToSeconds + minutesToSeconds + parseInt(seconds))
    }

  }, [days, hours, minutes, seconds])


  return (<DurationWrapper>
    <ManagedForm>
      <LabeledNumberInput label="Days" labelPosition={"after"} style={{ width: "25%" }}
        inputProps={{ min: 0, value: days }}
        name={props.name + "_duration__days"}
        onChange={e => setDays(e.target.value)}
        value={days}
      />


      <LabeledNumberInput label={"Hours"} labelPosition={"after"} style={{ width: "25%" }}
        inputProps={{ min: 0, value: hours }}
        name={props.name + "_duration__hours"}
        onChange={e => setHours(e.target.value)} />


      <LabeledNumberInput label="Minutes" labelPosition={"after"} style={{ width: "25%" }}
        inputProps={{ min: 0, value: minutes }}
        name={props.name + "_duration__minutes"}
        onChange={e => setMinutes(e.target.value)} />


      <LabeledNumberInput label="Seconds" labelPosition={"after"} style={{ width: "25%" }}
        inputProps={{ min: 0, value: seconds }}
        name={props.name + "_duration__seconds"}
        onChange={e => setSeconds(e.target.value)} />

    </ManagedForm>
  </DurationWrapper>)
});

export const StyledAutocompleteForTags = styled(Autocomplete)`
  color: #fff;
  font-size: 14px;

  & .MuiChip-root {
    font-size: 14px;

    & .MuiChip-label {
      background-color: var(--color-link);
      color: white;
      font-size: 14px;
    }
  }

  & .MuiSvgIcon-root {
    height: 1.5em;
    width: 1.5em;
  }

  &&& {
    & .MuiAutocomplete-input {
      border: 0;
      width: ${props => props.width ? props.width : "100%"};
      ${respondTo.mobile`  
        width: ${props => props.mobileWidth ? props.mobileWidth : "100%"};
    `}
    }
  }


  & .MuiOutlinedInput-root {
    border-radius: 5px;
  }

  & .MuiAutocomplete-input:hover {
    border: 0;
  }

`;


const useStylesTags = makeStyles((theme) => ({
  root: {
    width: '100%', "& > * + *": {
      marginTop: 3
    }
  }, valueContainer: {
    "& > :not(:last-child)": {
      marginRight: 1
    }, "& > *": {
      marginBottom: 1
    }
  }
}));


//tagOptions - List of tags we want to display in dropdown (defaults to select from)
//set inputProps:{{defaultValues: []}} for default tags
export const Tags = React.forwardRef(({
  name,
  id,
  width,
  mobileWidth,
  control,
  setValue,
  tagOptions = [],
  defaultValues,
  freeSolo,
  noDelete
}, ref) => {
  const classes = useStylesTags();
  const [valueTag, setValueTag] = useState(defaultValues || []);
  const onDelete = (tag) => () => setValueTag(valueTag.filter((v) => v !== tag));

  useEffect(() => {
    setValue(name, valueTag);
  }, [valueTag])

  return (<div className={classes.root}>
    <Controller
      control={control}
      name={name}
      render={() => <StyledAutocompleteForTags
        // width={width}
        // mobileWidth={mobileWidth}
        name={name}
        id={id}
        options={tagOptions}
        getOptionLabel={(option) => titleize(option)}
        onChange={(e, newValue) => setValueTag(newValue)}
        renderTags={() => null}
        renderOption={(option) => (<Typography style={{
          fontSize: "16px", fontFamily: 'Lato,"sans-serif'
        }}>{titleize(option)}</Typography>)}
        renderInput={(params) => <TextField
          placeholder={"Enter custom tags here and hit 'Enter' to have the tag created"}
          style={{ width: "100%", fontSize: "164px" }} {...params} fullWidth={true} variant="outlined"
          inputRef={ref}
          inputProps={{
            ...params.inputProps, style: { fontSize: "16px", fontFamily: 'Lato,"sans-serif' }
          }}

        />}
        multiple
        disableClearable
        freeSolo={freeSolo !== null && freeSolo === false ? false : true}
        autoComplete
        value={valueTag}
        defaultValue={valueTag}
      />}
    />
    <div className={classes.valueContainer}
      style={{ display: "flex", flexGrow: 1, flexWrap: "wrap", width: "650px", maxWidth: "650px" }}>
      {valueTag.map((v) => <Chip deleteIconColorPrimary={"white"} deleteIconColorSecondary={"white"} style={{
        fontSize: "16px", backgroundColor: "var(--color-link)", color: "white"
      }} variant="outlined" key={v} label={v} onDelete={!noDelete && onDelete(v)} />)}
    </div>
  </div>)
})

const RawAttachments = Snackbar.snackbarWrapper(React.forwardRef((props, ref) => {
  const [attaching, setAttaching] = useState(false);
  const [currentAttachments, setCurrentAttachments] = useState(props.defaultValues && props.defaultValues.length > 0 ? props.defaultValues : []);
  // const [attachmentRows, setAttachmentRows] = useState(props.required ? [0] : props.defaultValues?.length > 0 ? props.defaultValues.map((a, idx) => idx) : []);
  // const [hasAttachment, setHasAttachment] = useState(false)
  const [showRow, setShowRow] = useState(props.showRow === undefined ? true : props.showRow)
  const confirm = useConfirm();
  const [setSnackbar] = Snackbar.useSnackbar();
  const [uploading, setUploading] = useState(false)

  const onError = (error) => {
    setUploading(false)
    if (props.onError) {
      props.onError(error);
    } else {
      const message = error.message || error;
      setSnackbar(message);
    }
  }

  const onAttachmentRemove = (e, manager, idx) => {
    if (currentAttachments.length > 0 && currentAttachments[idx] && currentAttachments[idx].editURL) {
      confirm({
        title: <div style={{ fontSize: '17px' }}>This attachment will be permanently deleted. Do you really want
          to delete this attachment?</div>,
        content: "",
        cancellationButtonProps: {
          style: {
            fontSize: '17px'
          }
        },
        confirmationButtonProps: {
          style: {
            fontSize: '17px'
          }
        },
      })
        .then(() => {
          if (props.virtualDelete) {
            const newList = currentAttachments.filter((a, i) => i !== idx)
            setCurrentAttachments(newList);
            // setAttachmentRows(prev => prev.filter((r, i) => i !== idx));
            manager.remove(idx);
            props.onDelete && props.onDelete(newList);
          } else {
            VariableAPI.delete(currentAttachments[idx].editURL)
              .then((res) => {
                if (res.data.success) {
                  setCurrentAttachments(prev => prev.filter((a, i) => i !== idx));
                  // setAttachmentRows(prev => prev.filter((r, i) => i !== idx));
                  manager.remove(idx);

                  if (props.onDelete) {
                    props.onDelete()
                  }
                  // props.editing && window.location.reload();
                }
                else {
                  if (props.onDelete) {
                    props.onDelete()
                  }
                }
              })
              .catch(e => {
                onError("Action has failed");
              })
          }
        })
        .catch(() => {
          // onError("Action has failed");

        })
    } else {
      onError("Action has failed");

      try {
        setCurrentAttachments(prev => prev.filter((a, i) => i !== idx));
        // setAttachmentRows(prev => prev.filter((r, i) => i !== idx));
        manager.remove(idx);
      } catch (e) {
        console.error(e);
      }
    }
  }
  const onAttachmentCancel = (e, manager) => {
    e.preventDefault();

    if (attaching) {
      manager.reset();
      // setHasAttachment(false);
      setAttaching(false);
    }
  }
  console.log('currentAttachments', currentAttachments)
  return (
    <Grid container>
      <Grid item xs={12}><Typography sx={{ font: 'normal normal bold 16px/30px Lato' }}>{props.label}</Typography></Grid>
      <Grid item xs={12}>
        <AttachmentManager
          label={"Drag and Drop files here or  %{browse}  your computer"}
          limit={props.limit || 1}
          editor={props.editor || false}
          maxFileSizeInMB={props.maxFileSizeInMB || 5}
          // onRemoved={() => setHasAttachment(false)}
          // onAdded={() => setHasAttachment(true)}
          onComplete={() => setAttaching(false)}
          onError={onError}
          validate={props.validate}
          resetOnComplete={true}
          types={props.types || KnownTypes}
          dashboardProps={props.dashboardProps}
          onUploadError={onError}
          onUpload={() => setUploading(true)}
          onUploadSuccess={(file, response, newAttachment) => {
            const attachment = {
              title: newAttachment.filename, description: "", document: newAttachment
            }

            VariableAPI.post("/attachments", { attachment })
              .then((res) => {
                setUploading(false)
                if (res.data.success) {
                  if (props.onChange) {
                    props.onChange(res.data.cardData)
                  }
                  setCurrentAttachments(prev => [...prev, res.data.cardData])
                }

              })
              .catch(e => {
                setUploading(false)
                console.error(e);
                toast.error("Action has failed");
              })

          }}
          render={manager => (<Box sx={{ position: 'relative' }}>
            {manager.dnd({
              id: props.id || "DragAndDrop",
              locale: { strings: { dropHereOr: "Drag and Drop files here or  %{browse}  your computer" } }
            })}
            {uploading && <Stack
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: 'rgba(242,242,242,.5)' }}>
              <CircularProgress />
            </Stack>}
            {showRow && currentAttachments.length > 0 && <Box>
              <Typography>Uploaded files:</Typography>
              <List sx={{ color: "black" }}>


                {currentAttachments.map((row, idx) => {

                  return (
                    <ListItem
                      key={row.title}
                      sx={{
                        background: '#F2F2F2',
                        width: "100%",
                        marginBottom: "5px"
                      }}

                      secondaryAction={<>

                        <MuiButton
                          sx={{ color: 'var(--palette-error-main)' }}
                          onClick={(e) => {
                            onAttachmentRemove(e, manager, idx)
                          }}>
                          Remove
                        </MuiButton>
                      </>
                      }
                    >
                      <ArticleIcon fontSize='small' />
                      <Typography className={'truncatedText'}
                        sx={{ color: '#363636', width: '90%' }}>
                        {row.title}
                      </Typography>
                    </ListItem>
                  )
                })}


              </List>

            </Box>}
          </Box>)}
        />
      </Grid>
    </Grid>
  )
}));

export const Attachments = (props) => {
  return (
    <ConfirmProvider>
      <RawAttachments {...props} />
    </ConfirmProvider>
  )
}


export const LabeledInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1 1 auto;
  margin: 0 -15px 20px -15px;
  padding: 0 15px;
  width: ${props => props.width ? props.width : "auto"};

  ${respondTo.mobile`  
    width: ${props => props.mobileWidth ? props.mobileWidth : "100%"};
  `}
`
export const LabeledInput = ({
  name,
  label,
  id,
  register,
  errors,
  control,
  setValue,
  inputWidth,
  inputMobileWidth,
  labelWidth,
  labelMobileWidth,
  width,
  mobileWidth,
  labelPosition = "before",
  tagOptions,
  inputProps = {},
  labelProps = {},
  WrapperComponent = LabeledInputWrapper,
  InputComponent = TextInput,
  LabelComponent = Label,
  ...props
}) => {

  const _label = label || _snakeCase(name)
  const _id = id || _uniqueId(_snakeCase(_label) + '_');
  let errorClass = ""
  let errorMessage = ""
  try {
    const _errors = Array.from(new Set(jsonpath.query(errors, name))) || [];
    errorClass = _errors.length > 0 ? 'error' : "";
    errorMessage = _errors.map(error => error.message).join("<br/>");
  } catch (e) {
    console.error(e);
  }

  const labelElement = <LabelComponent width={labelWidth} mobileWidth={labelMobileWidth} className={errorClass}
    htmlFor={_id}
    style={labelProps.style} {...labelProps}
    dangerouslySetInnerHTML={{ __html: [label].join(' ') }}></LabelComponent>

  const inputElement = <>
    <InputComponent autoFocus={props.focus} control={control} setValue={setValue} width={inputWidth}
      mobileWidth={inputMobileWidth} ref={register} id={_id} tagOptions={tagOptions}
      name={name} section={props.section} {...inputProps}>
      {props.children}
    </InputComponent>


    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </>;


  switch (labelPosition) {
    case "before":
      return (<WrapperComponent width={width} mobileWidth={mobileWidth} {...props}>
        {labelElement}
        {inputElement}
      </WrapperComponent>)
    case "after":
      return (<WrapperComponent width={width} mobileWidth={mobileWidth} {...props}>
        {inputElement}
        {labelElement}
      </WrapperComponent>)

    default:
      return inputElement
  }
}


export const LabeledDurationInput = props => <LabeledInput InputComponent={DurationInput} {...props} />
export const LabeledHiddenInput = props => <LabeledInput InputComponent={HiddenInput} {...props} />

export const LabeledNumberInput = props => <LabeledInput InputComponent={NumberInput} {...props} />
export const LabeledDateInput = props => <LabeledInput InputComponent={DateInput} {...props} />
export const LabeledTimeInput = props => <LabeledInput InputComponent={TimeInput} {...props} />
export const LabeledTelInput = props => <LabeledInput InputComponent={TelInput} {...props} />
export const LabeledTrixInput = props => <LabeledInput InputComponent={TrixEditorInput} {...props} /> // New trix editor: TextEditorInput
// export const LabeledTextEditor = props => <LabeledInput InputComponent={TextEditorInput} {...props} />
export const LabeledSelect = props => <LabeledInput InputComponent={Select} {...props} />
export const LabeledTags = props => <LabeledInput InputComponent={Tags} {...props} />
export const LabeledCheckboxInput = props => <LabeledInput InputComponent={CheckboxInput} LabelComponent={CheckboxLabel}
  labelPosition={"after"} labelWidth={"auto"} {...props} />
export const LabeledRadioInput = props => <LabeledInput InputComponent={RadioInput} LabelComponent={RadioLabel}
  labelPosition={"after"} labelWidth={"auto"} {...props} />
// export const LabeledSketchPicker = props => <LabeledInput InputComponent={SketchPicker} {...props} />
export const LabeledAttachments = props => <LabeledInput InputComponent={Attachments} {...props} />


const useStyles = makeStyles({
  tooltip: {
    fontSize: '16px'
  }
});


const HelpDialog = (props) => {
  const [open, setOpen] = useState(props.open)

  return (<>
    <Dialog
      fullScreen={false}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby="responsive-dialog-title"
      disableEscapeKeyDown={true}
    >
      <Toolbar>
        <Typography sx={{ ml: 2, flex: 1 }} style={{ width: "99%" }} variant="h6" component="div">
          {props.title}
        </Typography>
      </Toolbar>

      <div dangerouslySetInnerHTML={{ __html: props.description }} />
    </Dialog>
  </>);
};

export const HelpTip = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (<>
    <a onClick={(e) => {
      setOpen(!open)
    }} style={props.style ? props.style : {}}>
      <Tooltip classes={{ tooltip: classes.tooltip }}
        title={<div dangerouslySetInnerHTML={{ __html: props.title }}></div>}>

        <HelpTipImage className={props.name} />


      </Tooltip>

    </a>
    <HelpDialog open={open} description={props.description} />
  </>)
}

export const StyledReactTooltip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    background-color: var(--color-sand-gray_light) !important;
    padding: 10px;
    border-radius: 10px !important;
    width: 180px;
    right: -110px;
    top: 30px;
    color: black;
  }
`


export const ManagedForm = ({
  defaultValues,
  createURL,
  updateURL,
  objectKey,
  cardEvents,
  FormComponent = Form,
  onSubmit,
  onCancel,
  onReset,
  onSuccess,
  debug = false,
  dryRun = false,
  ...props
}) => {

  const {
    register, handleSubmit, errors, setError, reset, control, getValues, setValue, watch, formState
  } = useForm({ defaultValues: defaultValues || {} });
  const [submitting, setSubmitting] = useState(false);


  const assignResponseErrors = responseErrors => {
    Object.entries(responseErrors).forEach(([name, messages]) => {
      messages.forEach(message => setError(name, { type: 'manual', message: message }))
    })
  }

  const handleSubmitAsync = async (data) => {
    if (data.attachments) {
      const tempArr = Object.keys(data.attachments).map(key => {
        const id = Number(key.split("_")[1]);
        if (!Boolean(id)) return;

        return ({
          id: id, title: data.attachments[`title_${id}`], description: data.attachments[`description_${id}`]
        })
      }).filter(a => a);
      const tempIds = tempArr.map(o => o.id);
      data.attachments = tempArr.filter(({ id }, index) => !tempIds.includes(id, index + 1));
    }

    if (data.certificates) {
      const tempArr = Object.keys(data.certificates).map(key => {
        const id = Number(key.split("_")[1]);
        if (!Boolean(id)) return;

        return ({
          id: id, title: data.certificates[`title_${id}`], description: data.certificates[`description_${id}`]
        })
      }).filter(a => a);
      const tempIds = tempArr.map(o => o.id);
      data.certificates = tempArr.filter(({ id }, index) => !tempIds.includes(id, index + 1));
    }

    setSubmitting(true)

    if (debug) {
      console.log("FORM DEBUG: Submitting")
      console.log("Update URL: ", updateURL)
      console.log("Create URL: ", createURL)
    }
    if (dryRun) console.log("Data: ", data);

    // const { isDirty, isValid } = formState;
    //
    // if (!isValid){
    //     if (props.setValid){
    //         props.setValid(false);
    //         return;
    //     }
    // } else{
    //     if (props.setValid){
    //         props.setValid(true)
    //     }
    // }

    if (createURL) {
      // let data = objectKey ? {[objectKey]: data}  : data

      await VariableAPI.post(createURL, { [objectKey]: data })
        .then(response => {
          if (response.data.success) {
            if (cardEvents && cardEvents.onAdded && response.data.cardData) {
              cardEvents.onAdded(response.data.cardData)
            }
            if (onSuccess) onSuccess();
          } else {
            if (debug) console.log("response", response.data);
            if (response.data.errors) {
              assignResponseErrors(response.data.errors);
            } else {
              toast.error("Action has failed");
            }
          }
        })
        .catch(e => {
          console.error(e);
          toast.error("Action has failed");
        })
    } else if (updateURL) {
      await VariableAPI.patch(updateURL, { [objectKey]: data })
        .then(response => {
          if (response.data.success) {
            if (cardEvents && cardEvents.onUpdated && response.data.cardData) {
              cardEvents.onUpdated(response.data.cardData)
            }
            if (onSuccess) onSuccess();
          } else {
            if (debug) console.log("response", response.data)
            if (response.data.errors) {
              assignResponseErrors(response.data.errors);
            } else {
              toast.error("Action has failed");
            }
          }
        })
        .catch(e => {
          console.error(e);
          toast.error("Action has failed");
        })
    } else if (onSubmit) {
      await onSubmit(data, errors, setError)
    }
    setSubmitting(false)
  }

  const handleCancel = e => {
    e.preventDefault();
    if (onCancel) onCancel();
  }

  const handleReset = e => {
    e.preventDefault();
    onReset ? onReset(reset) : reset();
  }

  function recursiveMap(children, fn) {
    return React.Children.map(children, child => {
      if (!React.isValidElement(child)) {
        return child;
      }

      if (child.props.children) {
        child = React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      }

      return child;
    });
  }


  return (<FormComponent autoComplete="off" className={props.className} onSubmit={handleSubmit(handleSubmitAsync)}>
    {/*TODO: Make this recursive*/}
    {React.Children.map(props.children, (child, index) => child && React.cloneElement(child, {
      register: register,
      errors: errors,
      setError: setError,
      control: control,
      setValue: setValue,
      submitting: submitting,
      watch: watch,
      getValues: getValues,
      focus: child.props?.name === props.focus,
      onCancel: handleCancel,
      onReset: handleReset,
    }))}
  </FormComponent>)
}


export const Wrap = styled.div`
  display: flex;
  width: 100%;
  height: 0;
` // use this to force a wrap inside the flex

export const Editor = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const getEditorState = (name) => {
    const { contentBlocks, entityMap } = htmlToDraft(name || '');
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  };

  let defaultValue;

  return (<Controller
    name={props.name}
    control={props.control}
    rules={props.validation}
    defaultValue={""}
    render={({
      field: { value, onChange, onBlur, ref }, fieldState: { error },
    }) => {
      // TODO: This saves the editorState as default value but got the text typed backwards issue
      let stateContent = '';
      let defaultEditorState;
      if (defaultValue || value) {
        defaultEditorState = getEditorState(defaultValue || value);
      }

      let editorStyle = {

        ...{
          color: 'black',
          border: "1px solid var(--palette-secondary-light)",
          borderRadius: "3px",
          height: "auto",
          maxWidth: "100%",
          minWidth: "100%",
          minHeight: "100px",
          resize: "both"
        },
        ...props.editorStyle
      }

      return (<>

        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                font: 'normal normal bold 16px/30px Lato',
                letterSpacing: '0px',
                color: '#363636',
                marginBottom: '5px'
              }}
            >{props.label || "Description"}</Typography>
            <DraftEditor
              {...props.editorOptions}
              autoComplete="off"
              id={props.name}
              name={props.name}
              defaultEditorState={defaultEditorState}
              onEditorStateChange={(editorState) => {
                const value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
                onChange(value)
              }}
              toolbarStyle={{
                color: 'black',
              }}

              editorStyle={editorStyle}

              toolbar={{
                options: ['inline', 'list', 'colorPicker', 'link'], inline: {
                  inDropdown: false,
                  className: undefined,
                  options: ['bold', 'italic', 'underline'],
                },
                link: {
                  defaultTargetOption: '_blank'
                }
              }}
              stripPastedStyles={true}
            />
          </Grid>
        </Grid>
      </>
      )
    }}
  />)
}

// Because DraftJs is flaky with defaults, I created this class to make sure we don't break everything else as we use this Editor everywhere.
export const EditorWithDefault = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const getEditorState = (name) => {
    const { contentBlocks, entityMap } = htmlToDraft(name || '');
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  };

  let defaultValue;

  return (<Controller
    name={props.name}
    control={props.control}
    rules={props.validation}
    defaultValue={props.defaultValue || ""}
    render={({
      field: { value, onChange, onBlur, ref }, fieldState: { error },
    }) => {
      // TODO: This saves the editorState as default value but got the text typed backwards issue
      let stateContent = '';
      let defaultEditorState;
      if (defaultValue || value) {
        defaultEditorState = getEditorState(defaultValue || value);
      }

      let editorStyle = {

        ...{
          color: 'black',
          border: "1px solid var(--palette-secondary-light)",
          borderRadius: "3px",
          height: "auto",
          maxWidth: "100%",
          minWidth: "100%",
          minHeight: "100px",
          resize: "both"
        },
        ...props.editorStyle
      }

      return (<>

        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                font: 'normal normal bold 16px/30px Lato',
                letterSpacing: '0px',
                color: '#363636',
                marginBottom: '5px'
              }}
            >{props.label || "Description"}</Typography>
            <DraftEditor
              {...props.editorOptions}
              autoComplete="off"
              id={props.name}
              name={props.name}
              defaultEditorState={defaultEditorState}
              onEditorStateChange={(editorState) => {
                const value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
                onChange(value)

                if (props.onChange) {
                  props.onChange(value)
                }
              }}
              toolbarStyle={{
                color: 'black',
              }}

              editorStyle={editorStyle}

              toolbar={{
                options: ['inline', 'list', 'colorPicker', 'link'], inline: {
                  inDropdown: false,
                  className: undefined,
                  options: ['bold', 'italic', 'underline'],
                },
              }}

            />
          </Grid>
        </Grid>
      </>
      )
    }}
  />)
}




export const parseError = (error) => {
  if (error) return error.message ? error.message : "Unhandled";
  return undefined
}

export const handleSubmitAsync = async (data, method, objectKey, url, onSuccess, onFail, debug = false) => {
  if (debug) {
    console.log("FORM DEBUG: Submitting")
    console.log("url: ", url)
    console.log("method: ", method)
    console.log("Data: ", data);
  } else {
    if (method === "create") {

      await VariableAPI.post(url, { [objectKey]: data })
        .then(response => {
          if (response.data.success) {
            onSuccess(response.data)
          } else if (response.status === 200) {
            if (response.data.success === undefined || response.data.success) {
              onSuccess(response.data)
            } else {
              onFail(response.data)
            }
          } else {
            onFail(response.data)
          }
        })
        .catch(e => {
          console.error(e);
          toast.error("Action has failed");
        })
    } else if (method === "update") {
      await VariableAPI.patch(url, { [objectKey]: data })
        .then(response => {
          if (response.data.success) {
            onSuccess(response.data)
          } else if (response.status === 200) {
            if (response.data.success === undefined || response.data.success) {
              onSuccess(response.data)
            } else {
              onFail(response.data)
            }
          } else {
            onFail(response.data)
          }
        })
        .catch(e => {
          console.error(e);
          toast.error("Action has failed");
        })
    } else if (method === "delete") {
      await VariableAPI.delete(url)
        .then(response => {
          if (response.data.success) {
            onSuccess(response.data)
          } else if (response.status === 200) {
            if (response.data.success === undefined || response.data.success) {
              onSuccess(response.data)
            } else {
              onFail(response.data)
            }
          } else {
            onFail(response.data)
          }
        })
        .catch(e => {
          console.error(e);
          toast.error("Action has failed");
        })
    }
  }
}

export const AsyncAutocomplete = ({ name, label, multiple, autoCompleteFunction, fullWidth }) => {

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(open && options.length === 0)

  useEffect(() => {
    if (!loading) return undefined;
    if (!open) setOptions([{ label: '', id: '' }]);

    async function fetchData() {
      let data = await autoCompleteFunction();
      setOptions(data);
      setLoading(false);
    }

    fetchData()
  }, [loading, open])


  return (
    <AutocompleteElement name={name}
      fullWidth={fullWidth || false}
      loading={loading}
      multiple={multiple}
      label={label}
      options={options ?? []} />
  )
}


export const RadioOrCheckboxElement = ({
  name,
  validation,
  required,
  parseError,
  label,
  control,
  helperText,
  labelProps,
  ...rest
}) => {

  return (<Controller
    name={name}
    control={control}
    rules={validation}
    render={({
      field: { value, onChange, onBlur, ref }, fieldState: { error },
    }) => {


      return (<>

        {rest.multiple && <Checkbox
          {...rest}
          color={rest.color || 'primary'}
          sx={{
            ...rest.sx,
            color: error ? 'error.main' : undefined,
          }}
          value={value}
          checked={!!value}
          onChange={(ev) => {
            onChange(!value)
            if (typeof rest.onChange === 'function') {
              rest.onChange(ev, !value)
            }
          }}
        />}

        {!rest.multiple &&
          <Radio
            {...rest}
            color={rest.color || 'primary'}
            sx={{
              ...rest.sx,
              color: error ? 'error.main' : undefined,
            }}
            value={value}
            checked={!!value}
            onChange={(ev) => {
              onChange(!value)
              if (typeof rest.onChange === 'function') {
                rest.onChange(ev, !value)
              }
            }}
          />
        }

      </>)
    }}
  />)
}
